define("discourse/plugins/discourse-navigation/discourse/components/footer/contacts", ["exports", "@glimmer/component", "discourse/plugins/discourse-navigation/discourse/components/helpers", "discourse/plugins/discourse-navigation/discourse/components/rich-text-html-renderer", "discourse/plugins/discourse-navigation/discourse/components/footer/tooltip", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helpers, _richTextHtmlRenderer, _tooltip, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Contacts extends _component.default {
    get contactLink() {
      return (0, _helpers.getSetting)('got-questions-book-call')?.itemsCollection?.items[0];
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="footer__contacts-wrapper">
          <div class="footer__contacts">
            <a
              class="footer__link"
              href="mailto:studienberatung@neuefische.de"
            >
              studienberatung@neuefische.de
            </a>
    
            <div>
              <a
                class="footer__link"
                href="tel:+494022859616"
              >
                040 - 22 85 96 16
              </a>
    
              <div class="working-time-wrapper">
                <p class="working-time">
                  {{ getTranslation 'footer_work_time' }}
                </p>
    
                <Tooltip
                  @text={{documentToHtmlString (getRichTranslation 'footer-tooltip')}}
                />
              </div>
            </div>
    
            <div class="footer__link">
              {{ getTranslation 'book-a-call' }}
            </div>
    
            <a
              class="btn btn_primary"
              target="_blank"
              rel="nofollow noreferrer"
              href="{{ this.contactLink.href }}"
            >
              {{ this.contactLink.text }}
            </a>
          </div>
        </div>
      
    */
    {
      "id": "OkA0tMiY",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"footer__contacts-wrapper\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"footer__contacts\"],[12],[1,\"\\n        \"],[10,3],[14,0,\"footer__link\"],[14,6,\"mailto:studienberatung@neuefische.de\"],[12],[1,\"\\n          studienberatung@neuefische.de\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[12],[1,\"\\n          \"],[10,3],[14,0,\"footer__link\"],[14,6,\"tel:+494022859616\"],[12],[1,\"\\n            040 - 22 85 96 16\\n          \"],[13],[1,\"\\n\\n          \"],[10,0],[14,0,\"working-time-wrapper\"],[12],[1,\"\\n            \"],[10,2],[14,0,\"working-time\"],[12],[1,\"\\n              \"],[1,[28,[32,0],[\"footer_work_time\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[8,[32,1],null,[[\"@text\"],[[28,[32,2],[[28,[32,3],[\"footer-tooltip\"],null]],null]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"footer__link\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"book-a-call\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,3],[14,0,\"btn btn_primary\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"nofollow noreferrer\"],[15,6,[29,[[30,0,[\"contactLink\",\"href\"]]]]],[12],[1,\"\\n          \"],[1,[30,0,[\"contactLink\",\"text\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-navigation/discourse/components/footer/contacts.js",
      "scope": () => [_helpers.getTranslation, _tooltip.default, _richTextHtmlRenderer.documentToHtmlString, _helpers.getRichTranslation],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Contacts;
});