define("discourse/plugins/discourse-navigation/discourse/components/footer-navigation", ["exports", "@glimmer/component", "discourse/plugins/discourse-navigation/discourse/components/helpers", "@ember/template", "@ember/object", "@glimmer/tracking", "discourse/lib/preload-store", "truth-helpers", "discourse/plugins/discourse-navigation/discourse/components/footer/logo", "discourse/plugins/discourse-navigation/discourse/components/footer/social-links", "discourse/plugins/discourse-navigation/discourse/components/footer/contacts", "discourse/plugins/discourse-navigation/discourse/components/footer/column", "discourse/plugins/discourse-navigation/discourse/components/footer/link", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helpers, _template, _object, _tracking, _preloadStore, _truthHelpers, _logo, _socialLinks, _contacts, _column, _link, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FooterNavigation extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "data", [_tracking.tracked], function () {
      return null;
    }))();
    #data = (() => (dt7948.i(this, "data"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "legalData", [_tracking.tracked], function () {
      return null;
    }))();
    #legalData = (() => (dt7948.i(this, "legalData"), void 0))();
    constructor() {
      super(...arguments);
      this.getNavigationData();
      this.getLegalNavigationData();
    }
    get currentYear() {
      return new Date().getFullYear();
    }
    getNavigationData() {
      const siteData = _preloadStore.default.get('site');
      if (siteData && siteData.navigation_data) {
        this.data = siteData.navigation_data[(0, _helpers.getLocale)()].footer;
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "getNavigationData", [_object.action]))();
    getLegalNavigationData() {
      const siteData = _preloadStore.default.get('site');
      if (siteData && siteData.navigation_data) {
        this.legalData = siteData.navigation_data[(0, _helpers.getLocale)()].legal;
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "getLegalNavigationData", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <footer class="footer_container">
          <div class="container container_footer">
            <div class="footer__inner">
              <div class="footer__info">
                {{Logo}}
                {{SocialLinks}}
    
                <div class="footer__title">
                  <h2>{{ htmlSafe (formatText (getTranslation 'footer-headline')) }}</h2>
                </div>
    
                {{Contacts}}
              </div>
    
              <hr class="footer__divider">
    
              <div class="footer-nav__container">
                {{#each this.data.itemsCollection.items as |item|}}
                  {{#if (eq item.__typename 'Nav')}}
                    <Column @item={{item}} />
                  {{/if}}
                {{/each}}
              </div>
    
              <div class="footer-rights">
                <div class="footer-rights__links">
                  {{#each this.legalData.itemsCollection.items as |item|}}
                    {{#if (isLinkItem item.__typename)}}
                      <Link @item={{item}} />
                    {{/if}}
                  {{/each}}
                </div>
    
                <div
                  class="footer-nav__link"
                  style="color: #9fa1a8"
                >
                  © {{ this.currentYear }} neuefische GmbH
                </div>
              </div>
            </div>
          </div>
        </footer>
      
    */
    {
      "id": "HkNHkL0N",
      "block": "[[[1,\"\\n    \"],[10,\"footer\"],[14,0,\"footer_container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"container container_footer\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"footer__inner\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"footer__info\"],[12],[1,\"\\n            \"],[1,[32,0]],[1,\"\\n            \"],[1,[32,1]],[1,\"\\n\\n            \"],[10,0],[14,0,\"footer__title\"],[12],[1,\"\\n              \"],[10,\"h2\"],[12],[1,[28,[32,2],[[28,[32,3],[[28,[32,4],[\"footer-headline\"],null]],null]],null]],[13],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[1,[32,5]],[1,\"\\n          \"],[13],[1,\"\\n\\n          \"],[10,\"hr\"],[14,0,\"footer__divider\"],[12],[13],[1,\"\\n\\n          \"],[10,0],[14,0,\"footer-nav__container\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"data\",\"itemsCollection\",\"items\"]]],null]],null],null,[[[41,[28,[32,6],[[30,1,[\"__typename\"]],\"Nav\"],null],[[[1,\"                \"],[8,[32,7],null,[[\"@item\"],[[30,1]]],null],[1,\"\\n\"]],[]],null]],[1]],null],[1,\"          \"],[13],[1,\"\\n\\n          \"],[10,0],[14,0,\"footer-rights\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"footer-rights__links\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"legalData\",\"itemsCollection\",\"items\"]]],null]],null],null,[[[41,[28,[32,8],[[30,2,[\"__typename\"]]],null],[[[1,\"                  \"],[8,[32,9],null,[[\"@item\"],[[30,2]]],null],[1,\"\\n\"]],[]],null]],[2]],null],[1,\"            \"],[13],[1,\"\\n\\n            \"],[10,0],[14,0,\"footer-nav__link\"],[14,5,\"color: #9fa1a8\"],[12],[1,\"\\n              © \"],[1,[30,0,[\"currentYear\"]]],[1,\" neuefische GmbH\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"item\",\"item\"],false,[\"each\",\"-track-array\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-navigation/discourse/components/footer-navigation.js",
      "scope": () => [_logo.default, _socialLinks.default, _template.htmlSafe, _helpers.formatText, _helpers.getTranslation, _contacts.default, _truthHelpers.eq, _column.default, _helpers.isLinkItem, _link.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FooterNavigation;
});