define("discourse/plugins/discourse-navigation/discourse/components/header/cta-button", ["exports", "@glimmer/component", "discourse/plugins/discourse-navigation/discourse/components/helpers", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helpers, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CTAButton extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <a
          class="btn btn_primary"
          href="{{ getMainWebsiteUrl 'apply-now/step-1' }}"
        >
          {{ getTranslation 'cta_apply-now_header' }}
        </a>
      
    */
    {
      "id": "D9guS7FH",
      "block": "[[[1,\"\\n    \"],[10,3],[14,0,\"btn btn_primary\"],[15,6,[29,[[28,[32,0],[\"apply-now/step-1\"],null]]]],[12],[1,\"\\n      \"],[1,[28,[32,1],[\"cta_apply-now_header\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-navigation/discourse/components/header/cta-button.js",
      "scope": () => [_helpers.getMainWebsiteUrl, _helpers.getTranslation],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CTAButton;
});