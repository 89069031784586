define("discourse/plugins/discourse-navigation/discourse/components/header/navigation/button", ["exports", "@glimmer/component", "@ember/object", "@ember/modifier", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _modifier, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NavItem extends _component.default {
    onClick() {
      this.args.onClick(this.args.itemId);
    }
    static #_ = (() => dt7948.n(this.prototype, "onClick", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="nav-item active">
          <button
            class="header__link {{if this.args.active 'active'}}"
            type="button"
            {{on "click" this.onClick}}
          >
            {{this.args.label}}
          </button>
        </div>
      
    */
    {
      "id": "ruY1MYSg",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"nav-item active\"],[12],[1,\"\\n      \"],[11,\"button\"],[16,0,[29,[\"header__link \",[52,[30,0,[\"args\",\"active\"]],\"active\"]]]],[24,4,\"button\"],[4,[32,0],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n        \"],[1,[30,0,[\"args\",\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-navigation/discourse/components/header/navigation/button.js",
      "scope": () => [_modifier.on],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = NavItem;
});