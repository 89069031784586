define("discourse/plugins/discourse-navigation/discourse/components/header/menu/mobile-column", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/helper", "discourse/plugins/discourse-navigation/discourse/components/header/menu/button", "discourse/plugins/discourse-navigation/discourse/components/header/menu/mobile-column-row", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _helper, _button, _mobileColumnRow, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function getColumnById(items, columnId) {
    return items.find(item => item && item.__typename === 'Nav' && item.sys.id === columnId);
  }
  class MobileColumn extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "menuLevels", [_tracking.tracked], function () {
      return {
        top: null,
        second: null
      };
    }))();
    #menuLevels = (() => (dt7948.i(this, "menuLevels"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "showColumnItems", [_tracking.tracked], function () {
      return false;
    }))();
    #showColumnItems = (() => (dt7948.i(this, "showColumnItems"), void 0))();
    get menuLevelItems() {
      const top = getColumnById(this.args.column?.itemsCollection?.items || [], this.menuLevels.top);
      const second = getColumnById(top?.itemsCollection?.items || [], this.menuLevels.second);
      return {
        top,
        second
      };
    }
    toggleShowColumnItems() {
      this.showColumnItems = !this.showColumnItems;
    }
    static #_3 = (() => dt7948.n(this.prototype, "toggleShowColumnItems", [_object.action]))();
    handleClickTop(itemId) {
      this.menuLevels = {
        top: itemId,
        second: null
      };
    }
    static #_4 = (() => dt7948.n(this.prototype, "handleClickTop", [_object.action]))();
    handleClickSecond(itemId) {
      this.menuLevels = {
        top: this.menuLevels.top,
        second: itemId
      };
    }
    static #_5 = (() => dt7948.n(this.prototype, "handleClickSecond", [_object.action]))();
    resetState() {
      if (this.menuLevels.top !== null || this.menuLevels.second !== null) {
        this.menuLevels = {
          top: null,
          second: null
        };
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "resetState", [_object.action]))();
    handleBackTop() {
      this.handleClickTop(null);
    }
    static #_7 = (() => dt7948.n(this.prototype, "handleBackTop", [_object.action]))();
    handleBackSecond() {
      this.handleClickSecond(null);
    }
    static #_8 = (() => dt7948.n(this.prototype, "handleBackSecond", [_object.action]))();
    static #_9 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="header-submenu__column_wrapper">
          <div class="header-submenu">
            <div class="nav-column__items">
              <Button
                @item={{this.args.column}}
                @onClick={{fn this.toggleShowColumnItems}}
                @viewPromotion={{true}}
              />
            </div>
          </div>
    
          {{#if this.showColumnItems}}
            <ColumnRow
              @zIndex={{40}}
              @column={{this.args.column}}
              @viewPromotion={{true}}
              @onClick={{this.handleClickTop}}
              @onClose={{this.args.onClose}}
              @onBackClick={{this.toggleShowColumnItems}}
            />
          {{/if}}
    
          {{#if this.menuLevelItems.top}}
            <ColumnRow
              @zIndex={{41}}
              @column={{this.menuLevelItems.top}}
              @viewPromotion={{true}}
              @onClick={{this.handleClickSecond}}
              @onClose={{this.args.onClose}}
              @onBackClick={{this.handleBackTop}}
            />
          {{/if}}
    
          {{#if this.menuLevelItems.second}}
            <ColumnRow
              @zIndex={{42}}
              @column={{this.menuLevelItems.second}}
              @viewPromotion={{false}}
              @onClose={{this.args.onClose}}
              @onBackClick={{this.handleBackSecond}}
            />
          {{/if}}
        </div>
      
    */
    {
      "id": "Q1w7yBxz",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"header-submenu__column_wrapper\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"header-submenu\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"nav-column__items\"],[12],[1,\"\\n          \"],[8,[32,0],null,[[\"@item\",\"@onClick\",\"@viewPromotion\"],[[30,0,[\"args\",\"column\"]],[28,[32,1],[[30,0,[\"toggleShowColumnItems\"]]],null],true]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showColumnItems\"]],[[[1,\"        \"],[8,[32,2],null,[[\"@zIndex\",\"@column\",\"@viewPromotion\",\"@onClick\",\"@onClose\",\"@onBackClick\"],[40,[30,0,[\"args\",\"column\"]],true,[30,0,[\"handleClickTop\"]],[30,0,[\"args\",\"onClose\"]],[30,0,[\"toggleShowColumnItems\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"menuLevelItems\",\"top\"]],[[[1,\"        \"],[8,[32,2],null,[[\"@zIndex\",\"@column\",\"@viewPromotion\",\"@onClick\",\"@onClose\",\"@onBackClick\"],[41,[30,0,[\"menuLevelItems\",\"top\"]],true,[30,0,[\"handleClickSecond\"]],[30,0,[\"args\",\"onClose\"]],[30,0,[\"handleBackTop\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"menuLevelItems\",\"second\"]],[[[1,\"        \"],[8,[32,2],null,[[\"@zIndex\",\"@column\",\"@viewPromotion\",\"@onClose\",\"@onBackClick\"],[42,[30,0,[\"menuLevelItems\",\"second\"]],false,[30,0,[\"args\",\"onClose\"]],[30,0,[\"handleBackSecond\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-navigation/discourse/components/header/menu/mobile-column.js",
      "scope": () => [_button.default, _helper.fn, _mobileColumnRow.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MobileColumn;
});