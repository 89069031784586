define("discourse/plugins/discourse-navigation/discourse/components/footer/link", ["exports", "@glimmer/component", "truth-helpers", "discourse/plugins/discourse-navigation/discourse/components/helpers", "@ember/helper", "@ember/component", "@ember/template-factory"], function (_exports, _component, _truthHelpers, _helpers, _helper, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Link extends _component.default {
    get isDisabled() {
      return (this.args.item.meta?.disabledLocales ?? []).includes(I18n.locale);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#unless this.isDisabled}}
          {{#if (eq this.args.item.__typename 'Page')}}
            <a
              class="footer-nav__link"
              href="{{ getMainWebsiteUrl this.args.item.slug }}"
            >
              {{ removeTinyMarkdown (or this.args.item.navTitle this.args.item.titleEyebrow this.args.item.title) }}
            </a>
          {{/if}}
    
          {{#if (eq this.args.item.__typename 'Bootcamp')}}
            <a
              class="footer-nav__link"
              href="{{ getMainWebsiteUrl (concat 'bootcamp/' this.args.item.slug) }}"
            >
              {{ removeTinyMarkdown (or this.args.item.footerNavigationName this.args.item.title) }}
            </a>
          {{/if}}
    
          {{#if (eq this.args.item.__typename 'City')}}
            <a
              class="footer-nav__link"
              href="{{ getMainWebsiteUrl this.args.item.slug }}"
            >
              {{ removeTinyMarkdown this.args.item.name }}
            </a>
          {{/if}}
    
          {{#if (eq this.args.item.__typename 'Link')}}
            <a
              class="footer-nav__link"
              href="{{ getMainWebsiteUrl this.args.item.href }}"
              download="{{ this.args.item.download }}"
              target="{{ if this.args.item.targetBlank '_blank' }}"
              rel="{{ if this.args.item.targetBlank 'nofollow' }}"
            >
              {{ or this.args.item.text }}
            </a>
          {{/if}}
        {{/unless}}
      
    */
    {
      "id": "mxRNl6gE",
      "block": "[[[1,\"\\n\"],[41,[51,[30,0,[\"isDisabled\"]]],[[[41,[28,[32,0],[[30,0,[\"args\",\"item\",\"__typename\"]],\"Page\"],null],[[[1,\"        \"],[10,3],[14,0,\"footer-nav__link\"],[15,6,[29,[[28,[32,1],[[30,0,[\"args\",\"item\",\"slug\"]]],null]]]],[12],[1,\"\\n          \"],[1,[28,[32,2],[[28,[32,3],[[30,0,[\"args\",\"item\",\"navTitle\"]],[30,0,[\"args\",\"item\",\"titleEyebrow\"]],[30,0,[\"args\",\"item\",\"title\"]]],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"args\",\"item\",\"__typename\"]],\"Bootcamp\"],null],[[[1,\"        \"],[10,3],[14,0,\"footer-nav__link\"],[15,6,[29,[[28,[32,1],[[28,[32,4],[\"bootcamp/\",[30,0,[\"args\",\"item\",\"slug\"]]],null]],null]]]],[12],[1,\"\\n          \"],[1,[28,[32,2],[[28,[32,3],[[30,0,[\"args\",\"item\",\"footerNavigationName\"]],[30,0,[\"args\",\"item\",\"title\"]]],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"args\",\"item\",\"__typename\"]],\"City\"],null],[[[1,\"        \"],[10,3],[14,0,\"footer-nav__link\"],[15,6,[29,[[28,[32,1],[[30,0,[\"args\",\"item\",\"slug\"]]],null]]]],[12],[1,\"\\n          \"],[1,[28,[32,2],[[30,0,[\"args\",\"item\",\"name\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"args\",\"item\",\"__typename\"]],\"Link\"],null],[[[1,\"        \"],[10,3],[14,0,\"footer-nav__link\"],[15,6,[29,[[28,[32,1],[[30,0,[\"args\",\"item\",\"href\"]]],null]]]],[15,\"download\",[29,[[30,0,[\"args\",\"item\",\"download\"]]]]],[15,\"target\",[29,[[52,[30,0,[\"args\",\"item\",\"targetBlank\"]],\"_blank\"]]]],[15,\"rel\",[29,[[52,[30,0,[\"args\",\"item\",\"targetBlank\"]],\"nofollow\"]]]],[12],[1,\"\\n          \"],[1,[28,[32,3],[[30,0,[\"args\",\"item\",\"text\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"  \"]],[],false,[\"unless\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-navigation/discourse/components/footer/link.js",
      "scope": () => [_truthHelpers.eq, _helpers.getMainWebsiteUrl, _helpers.removeTinyMarkdown, _truthHelpers.or, _helper.concat],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Link;
});