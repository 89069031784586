define("discourse/plugins/discourse-navigation/discourse/components/helpers", ["exports", "discourse/lib/preload-store"], function (_exports, _preloadStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatText = formatText;
  _exports.getLocale = getLocale;
  _exports.getMainWebsiteUrl = getMainWebsiteUrl;
  _exports.getRichTranslation = getRichTranslation;
  _exports.getSetting = getSetting;
  _exports.getTranslation = getTranslation;
  _exports.isLinkItem = isLinkItem;
  _exports.removeTinyMarkdown = removeTinyMarkdown;
  const baseMainWebsiteUrl = 'https://www.neuefische.de';
  function isLinkItem(typename) {
    return ['Page', 'Bootcamp', 'City', 'Link'].includes(typename);
  }
  function getLocale() {
    return I18n.locale === 'de' ? 'de' : 'en';
  }
  function getMainWebsiteUrl(path) {
    const pathDEReplacements = {
      'apply-now/step-1': 'jetzt-bewerben/schritt-1'
    };
    if (getLocale() === 'de') {
      return `${baseMainWebsiteUrl}/${pathDEReplacements[path] || path}`;
    }
    if (path.startsWith('/en/')) {
      return `${baseMainWebsiteUrl}${path}`;
    }
    if (path.startsWith('https://') || path.startsWith('http://')) {
      return path;
    }
    return `${baseMainWebsiteUrl}/en/${path}`;
  }
  function getTranslations() {
    return _preloadStore.default.get('site').navigation_data[getLocale()].translations || [];
  }
  function getTranslation(id) {
    const translations = getTranslations();
    return translations.find(t => t.id === id)?.translation || id;
  }
  function getRichTranslation(id) {
    const translations = getTranslations();
    return translations.find(t => t.id === id)?.translationRichText?.json ?? {};
  }
  function getSetting(id) {
    const settings = _preloadStore.default.get('site').navigation_data[getLocale()].global_settings || [];
    return settings.find(t => t.codeId === id);
  }
  function formatText(text) {
    let formattedText = text.replace(/\\n/g, '<br>');
    formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<span class="brand-color">$1</span>');
    return formattedText;
  }
  function removeTinyMarkdown(string) {
    if (!string || typeof string !== 'string' || typeof string.replaceAll !== 'function') {
      return string;
    }
    return string.replaceAll('**', '').replaceAll('~~', '').replaceAll('__', '').replaceAll('\\n', '');
  }
});