define("discourse/plugins/discourse-navigation/discourse/components/header/menu/column-row", ["exports", "@glimmer/component", "discourse/plugins/discourse-navigation/discourse/components/helpers", "truth-helpers", "discourse/plugins/discourse-navigation/discourse/components/header/menu/link", "discourse/plugins/discourse-navigation/discourse/components/header/menu/button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helpers, _truthHelpers, _link, _button, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ColumnRow extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="{{if this.args.isShowBorder 'column-has-border-right'}}">
          <div class="header-submenu {{if this.args.isSticky 'column-is-sticky'}}">
            {{#if this.args.column.title}}
              <span class="header-submenu__title">
                {{or this.args.column.columnName this.args.column.title}}
              </span>
            {{/if}}
    
            <div class="nav-column__items">
              {{#each this.args.column.itemsCollection.items as |item|}}
                {{#if (isLinkItem item.__typename) }}
                  <Link
                    @item={{item}}
                    @inColumn={{true}}
                    @shouldAnimate={{this.args.shouldAnimate}}
                  />
                {{/if}}
    
                {{#if (eq item.__typename "Nav")}}
                  <Button
                    @item={{item}}
                    @inColumn={{true}}
                    @active={{eq this.args.activeNavItem item.sys.id}}
                    @viewPromotion={{this.args.viewPromotion}}
                    @onMouseEnter={{this.args.onMouseEnter}}
                    @shouldAnimate={{this.args.shouldAnimate}}
                  />
                {{/if}}
              {{/each}}
            </div>
          </div>
        </div>
      
    */
    {
      "id": "b2285qqt",
      "block": "[[[1,\"\\n    \"],[10,0],[15,0,[29,[[52,[30,0,[\"args\",\"isShowBorder\"]],\"column-has-border-right\"]]]],[12],[1,\"\\n      \"],[10,0],[15,0,[29,[\"header-submenu \",[52,[30,0,[\"args\",\"isSticky\"]],\"column-is-sticky\"]]]],[12],[1,\"\\n\"],[41,[30,0,[\"args\",\"column\",\"title\"]],[[[1,\"          \"],[10,1],[14,0,\"header-submenu__title\"],[12],[1,\"\\n            \"],[1,[28,[32,0],[[30,0,[\"args\",\"column\",\"columnName\"]],[30,0,[\"args\",\"column\",\"title\"]]],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[10,0],[14,0,\"nav-column__items\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"args\",\"column\",\"itemsCollection\",\"items\"]]],null]],null],null,[[[41,[28,[32,1],[[30,1,[\"__typename\"]]],null],[[[1,\"              \"],[8,[32,2],null,[[\"@item\",\"@inColumn\",\"@shouldAnimate\"],[[30,1],true,[30,0,[\"args\",\"shouldAnimate\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[32,3],[[30,1,[\"__typename\"]],\"Nav\"],null],[[[1,\"              \"],[8,[32,4],null,[[\"@item\",\"@inColumn\",\"@active\",\"@viewPromotion\",\"@onMouseEnter\",\"@shouldAnimate\"],[[30,1],true,[28,[32,3],[[30,0,[\"args\",\"activeNavItem\"]],[30,1,[\"sys\",\"id\"]]],null],[30,0,[\"args\",\"viewPromotion\"]],[30,0,[\"args\",\"onMouseEnter\"]],[30,0,[\"args\",\"shouldAnimate\"]]]],null],[1,\"\\n\"]],[]],null]],[1]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"item\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-navigation/discourse/components/header/menu/column-row.js",
      "scope": () => [_truthHelpers.or, _helpers.isLinkItem, _link.default, _truthHelpers.eq, _button.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ColumnRow;
});