define("discourse/plugins/discourse-navigation/discourse/components/header/menu/column", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/plugins/discourse-navigation/discourse/components/header/menu/column-row", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _columnRow, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MenuColumn extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "menuLevels", [_tracking.tracked], function () {
      return {
        top: null,
        second: null
      };
    }))();
    #menuLevels = (() => (dt7948.i(this, "menuLevels"), void 0))();
    get menuLevelItems() {
      const topItem = this.args.column?.itemsCollection?.items.find(item => item && item.__typename === 'Nav' && item.sys.id === this.menuLevels.top);
      const secondItem = topItem?.itemsCollection?.items.find(item => item && item.__typename === 'Nav' && item.sys.id === this.menuLevels.second);
      return {
        top: topItem,
        second: secondItem
      };
    }
    handleMouseEnterTop(itemId) {
      this.menuLevels = {
        top: itemId,
        second: null
      };
    }
    static #_2 = (() => dt7948.n(this.prototype, "handleMouseEnterTop", [_object.action]))();
    handleMouseEnterSecond(itemId) {
      this.menuLevels = {
        ...this.menuLevels,
        second: itemId
      };
    }
    static #_3 = (() => dt7948.n(this.prototype, "handleMouseEnterSecond", [_object.action]))();
    resetState() {
      if (this.menuLevels.top === null && this.menuLevels.second === null) {
        return;
      }
      this.menuLevels = {
        top: null,
        second: null
      };
    }
    static #_4 = (() => dt7948.n(this.prototype, "resetState", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="header-submenu__column_wrapper">
          <ColumnRow
            @column={{this.args.column}}
            @activeNavItem={{this.menuLevels.top}}
            @onMouseEnter={{this.handleMouseEnterTop}}
            @isShowBorder={{this.menuLevels.top}}
            @viewPromotion={{true}}
          />
    
          {{#if this.menuLevelItems.top}}
            <ColumnRow
              @column={{this.menuLevelItems.top}}
              @activeNavItem={{this.menuLevels.second}}
              @onMouseEnter={{this.handleMouseEnterSecond}}
              @isShowBorder={{true}}
              @shouldAnimate={{true}}
              @viewPromotion={{true}}
            />
          {{/if}}
    
          {{#if this.menuLevelItems.second}}
            <ColumnRow
              @column={{this.menuLevelItems.second}}
              @isShowBorder={{true}}
              @shouldAnimate={{true}}
              @isSticky={{true}}
            />
          {{/if}}
        </div>
      
    */
    {
      "id": "6epgGqLx",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"header-submenu__column_wrapper\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@column\",\"@activeNavItem\",\"@onMouseEnter\",\"@isShowBorder\",\"@viewPromotion\"],[[30,0,[\"args\",\"column\"]],[30,0,[\"menuLevels\",\"top\"]],[30,0,[\"handleMouseEnterTop\"]],[30,0,[\"menuLevels\",\"top\"]],true]],null],[1,\"\\n\\n\"],[41,[30,0,[\"menuLevelItems\",\"top\"]],[[[1,\"        \"],[8,[32,0],null,[[\"@column\",\"@activeNavItem\",\"@onMouseEnter\",\"@isShowBorder\",\"@shouldAnimate\",\"@viewPromotion\"],[[30,0,[\"menuLevelItems\",\"top\"]],[30,0,[\"menuLevels\",\"second\"]],[30,0,[\"handleMouseEnterSecond\"]],true,true,true]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"menuLevelItems\",\"second\"]],[[[1,\"        \"],[8,[32,0],null,[[\"@column\",\"@isShowBorder\",\"@shouldAnimate\",\"@isSticky\"],[[30,0,[\"menuLevelItems\",\"second\"]],true,true,true]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-navigation/discourse/components/header/menu/column.js",
      "scope": () => [_columnRow.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MenuColumn;
});