define("discourse/plugins/discourse-navigation/discourse/components/rich-text-html-renderer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaultNodeRenderers = _exports.defaultMarkRenderers = void 0;
  _exports.documentToHtmlString = documentToHtmlString;
  _exports.nodeListToHtmlString = nodeListToHtmlString;
  _exports.nodeToHtmlString = nodeToHtmlString;
  const BLOCKS = {
    DOCUMENT: 'document',
    PARAGRAPH: 'paragraph',
    HEADING_1: 'heading-1',
    HEADING_2: 'heading-2',
    HEADING_3: 'heading-3',
    HEADING_4: 'heading-4',
    HEADING_5: 'heading-5',
    HEADING_6: 'heading-6',
    OL_LIST: 'ordered-list',
    UL_LIST: 'unordered-list',
    LIST_ITEM: 'list-item',
    HR: 'hr',
    QUOTE: 'blockquote',
    EMBEDDED_ENTRY: 'embedded-entry-block',
    EMBEDDED_ASSET: 'embedded-asset-block',
    EMBEDDED_RESOURCE: 'embedded-resource-block',
    TABLE: 'table',
    TABLE_ROW: 'table-row',
    TABLE_CELL: 'table-cell',
    TABLE_HEADER_CELL: 'table-header-cell'
  };
  const INLINES = {
    HYPERLINK: 'hyperlink',
    ENTRY_HYPERLINK: 'entry-hyperlink',
    ASSET_HYPERLINK: 'asset-hyperlink',
    RESOURCE_HYPERLINK: 'resource-hyperlink',
    EMBEDDED_ENTRY: 'embedded-entry-inline',
    EMBEDDED_RESOURCE: 'embedded-resource-inline'
  };
  const MARKS = {
    BOLD: 'bold',
    ITALIC: 'italic',
    UNDERLINE: 'underline',
    CODE: 'code',
    SUPERSCRIPT: 'superscript',
    SUBSCRIPT: 'subscript',
    STRIKETHROUGH: 'strikethrough'
  };
  function escape(html) {
    return String(html).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;');
  }
  const attributeValue = value => `"${value.replace(/"/g, '&quot;')}"`;
  const defaultNodeRenderers = _exports.defaultNodeRenderers = {
    [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content)}</p>`,
    [BLOCKS.HEADING_1]: (node, next) => `<h1>${next(node.content)}</h1>`,
    [BLOCKS.HEADING_2]: (node, next) => `<h2>${next(node.content)}</h2>`,
    [BLOCKS.HEADING_3]: (node, next) => `<h3>${next(node.content)}</h3>`,
    [BLOCKS.HEADING_4]: (node, next) => `<h4>${next(node.content)}</h4>`,
    [BLOCKS.HEADING_5]: (node, next) => `<h5>${next(node.content)}</h5>`,
    [BLOCKS.HEADING_6]: (node, next) => `<h6>${next(node.content)}</h6>`,
    [BLOCKS.EMBEDDED_ENTRY]: (node, next) => `<div>${next(node.content)}</div>`,
    [BLOCKS.EMBEDDED_RESOURCE]: (node, next) => `<div>${next(node.content)}</div>`,
    [BLOCKS.UL_LIST]: (node, next) => `<ul>${next(node.content)}</ul>`,
    [BLOCKS.OL_LIST]: (node, next) => `<ol>${next(node.content)}</ol>`,
    [BLOCKS.LIST_ITEM]: (node, next) => `<li>${next(node.content)}</li>`,
    [BLOCKS.QUOTE]: (node, next) => `<blockquote>${next(node.content)}</blockquote>`,
    [BLOCKS.HR]: () => '<hr/>',
    [BLOCKS.TABLE]: (node, next) => `<table>${next(node.content)}</table>`,
    [BLOCKS.TABLE_ROW]: (node, next) => `<tr>${next(node.content)}</tr>`,
    [BLOCKS.TABLE_HEADER_CELL]: (node, next) => `<th>${next(node.content)}</th>`,
    [BLOCKS.TABLE_CELL]: (node, next) => `<td>${next(node.content)}</td>`,
    [INLINES.ASSET_HYPERLINK]: node => defaultInline(INLINES.ASSET_HYPERLINK, node),
    [INLINES.ENTRY_HYPERLINK]: node => defaultInline(INLINES.ENTRY_HYPERLINK, node),
    [INLINES.RESOURCE_HYPERLINK]: node => defaultInlineResource(INLINES.RESOURCE_HYPERLINK, node),
    [INLINES.EMBEDDED_ENTRY]: node => defaultInline(INLINES.EMBEDDED_ENTRY, node),
    [INLINES.EMBEDDED_RESOURCE]: node => defaultInlineResource(INLINES.EMBEDDED_RESOURCE, node),
    [INLINES.HYPERLINK]: (node, next) => {
      const href = typeof node.data.uri === 'string' ? node.data.uri : '';
      return `<a href=${attributeValue(href)}>${next(node.content)}</a>`;
    }
  };
  const defaultMarkRenderers = _exports.defaultMarkRenderers = {
    [MARKS.BOLD]: text => `<b>${text}</b>`,
    [MARKS.ITALIC]: text => `<i>${text}</i>`,
    [MARKS.UNDERLINE]: text => `<u>${text}</u>`,
    [MARKS.CODE]: text => `<code>${text}</code>`,
    [MARKS.SUPERSCRIPT]: text => `<sup>${text}</sup>`,
    [MARKS.SUBSCRIPT]: text => `<sub>${text}</sub>`,
    [MARKS.STRIKETHROUGH]: text => `<s>${text}</s>`
  };
  const defaultInline = (type, node) => `<span>type: ${escape(type)} id: ${escape(node.data.target.sys.id)}</span>`;
  const defaultInlineResource = (type, node) => `<span>type: ${escape(type)} urn: ${escape(node.data.target.sys.urn)}</span>`;

  /**
   * Serialize a Contentful Rich Text `document` to an html string.
   */
  function documentToHtmlString(richTextDocument) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (!richTextDocument || !richTextDocument.content) {
      return '';
    }
    return nodeListToHtmlString(richTextDocument.content, {
      renderNode: {
        ...defaultNodeRenderers,
        ...options.renderNode
      },
      renderMark: {
        ...defaultMarkRenderers,
        ...options.renderMark
      },
      preserveWhitespace: options.preserveWhitespace
    });
  }
  function nodeListToHtmlString(nodes, _ref) {
    let {
      renderNode,
      renderMark,
      preserveWhitespace
    } = _ref;
    return nodes.map(node => nodeToHtmlString(node, {
      renderNode,
      renderMark,
      preserveWhitespace
    })).join('');
  }
  const helpers = {
    isText: node => node.nodeType === 'text'
  };
  function nodeToHtmlString(node, _ref2) {
    let {
      renderNode,
      renderMark,
      preserveWhitespace
    } = _ref2;
    if (helpers.isText(node)) {
      let nodeValue = escape(node.value);

      // If preserveWhitespace is true, handle line breaks and spaces.
      if (preserveWhitespace) {
        nodeValue = nodeValue.replace(/\n/g, '<br/>').replace(/ {2,}/g, match => '&nbsp;'.repeat(match.length));
      }
      if (node.marks.length > 0) {
        return node.marks.reduce((value, mark) => {
          if (!renderMark[mark.type]) {
            return value;
          }
          return renderMark[mark.type](value);
        }, nodeValue);
      }
      return nodeValue;
    } else {
      const nextNode = nodes => nodeListToHtmlString(nodes, {
        renderMark,
        renderNode,
        preserveWhitespace
      });
      if (!node.nodeType || !renderNode[node.nodeType]) {
        // Figure what to return when passed an unrecognized node.
        return '';
      }
      return renderNode[node.nodeType](node, nextNode);
    }
  }
});