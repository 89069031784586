define("discourse/plugins/discourse-navigation/discourse/components/header/burger-button", ["exports", "@glimmer/component", "@ember/modifier", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BurgerButton extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <button
          {{ on 'click' this.args.onClick }}
          class="burger {{if this.args.isActive 'active'}}"
        >
          <span class="burger-line"></span>
          <span class="burger-line"></span>
        </button>
      
    */
    {
      "id": "vUBIi9JM",
      "block": "[[[1,\"\\n    \"],[11,\"button\"],[16,0,[29,[\"burger \",[52,[30,0,[\"args\",\"isActive\"]],\"active\"]]]],[4,[32,0],[\"click\",[30,0,[\"args\",\"onClick\"]]],null],[12],[1,\"\\n      \"],[10,1],[14,0,\"burger-line\"],[12],[13],[1,\"\\n      \"],[10,1],[14,0,\"burger-line\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-navigation/discourse/components/header/burger-button.js",
      "scope": () => [_modifier.on],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BurgerButton;
});