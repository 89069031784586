define("discourse/plugins/discourse-navigation/discourse/components/header/lang-switcher", ["exports", "@glimmer/component", "@ember/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LangSwitcher extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="header__languages">
          <a href="" class="language-selector">DE</a>
          <span>|</span>
          <a href="" class="language-selector active">EN</a>
        </div>
      
    */
    {
      "id": "V+Zi7as6",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"header__languages\"],[12],[1,\"\\n      \"],[10,3],[14,6,\"\"],[14,0,\"language-selector\"],[12],[1,\"DE\"],[13],[1,\"\\n      \"],[10,1],[12],[1,\"|\"],[13],[1,\"\\n      \"],[10,3],[14,6,\"\"],[14,0,\"language-selector active\"],[12],[1,\"EN\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-navigation/discourse/components/header/lang-switcher.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LangSwitcher;
});