define("discourse/plugins/discourse-navigation/discourse/components/header/menu/featured-article", ["exports", "@glimmer/component", "discourse/plugins/discourse-navigation/discourse/components/helpers", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helpers, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FeaturedArticle extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="header-menu__image-link_wrapper">
          <a
            href="{{this.args.article.url}}"
            class="header-menu__image-link"
          >
            <figure>
              <img
                width="346px"
                height="230px"
                src="{{this.args.article.image}}"
                alt="{{this.args.article.title}}"
              >
            </figure>
    
            <span class="featured-image-column__placeholder">
              {{ getTranslation 'Featured Article' }}
            </span>
    
            <span class="featured-image-column__title">
              {{this.args.article.title}}
            </span>
          </a>
        </div>
      
    */
    {
      "id": "dv+0WoAJ",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"header-menu__image-link_wrapper\"],[12],[1,\"\\n      \"],[10,3],[15,6,[29,[[30,0,[\"args\",\"article\",\"url\"]]]]],[14,0,\"header-menu__image-link\"],[12],[1,\"\\n        \"],[10,\"figure\"],[12],[1,\"\\n          \"],[10,\"img\"],[14,\"width\",\"346px\"],[14,\"height\",\"230px\"],[15,\"src\",[29,[[30,0,[\"args\",\"article\",\"image\"]]]]],[15,\"alt\",[29,[[30,0,[\"args\",\"article\",\"title\"]]]]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,1],[14,0,\"featured-image-column__placeholder\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"Featured Article\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,1],[14,0,\"featured-image-column__title\"],[12],[1,\"\\n          \"],[1,[30,0,[\"args\",\"article\",\"title\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-navigation/discourse/components/header/menu/featured-article.js",
      "scope": () => [_helpers.getTranslation],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FeaturedArticle;
});