define("discourse/plugins/discourse-navigation/discourse/components/header/navigation/link", ["exports", "@glimmer/component", "@ember/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Link extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="nav-item active">
          <a
            class="header__link"
            href="{{this.args.href}}"
          >
            {{this.args.label}}
          </a>
        </div>
      
    */
    {
      "id": "DJ26muU7",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"nav-item active\"],[12],[1,\"\\n      \"],[10,3],[14,0,\"header__link\"],[15,6,[29,[[30,0,[\"args\",\"href\"]]]]],[12],[1,\"\\n        \"],[1,[30,0,[\"args\",\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-navigation/discourse/components/header/navigation/link.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Link;
});