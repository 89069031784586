define("discourse/plugins/discourse-navigation/discourse/components/header/menu/button", ["exports", "@glimmer/component", "@ember/modifier", "truth-helpers", "@ember/object", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _truthHelpers, _object, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Button extends _component.default {
    get isDisabled() {
      return (this.args.item.disableForLocales || []).includes(I18n.locale);
    }
    onClick() {
      if (this.args.onClick) {
        this.args.onClick(this.args.item.sys.id);
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "onClick", [_object.action]))();
    onMouseEnter() {
      if (this.args.onMouseEnter) {
        this.args.onMouseEnter(this.args.item.sys.id);
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "onMouseEnter", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#unless this.isDisabled}}
          <button
            type="button"
            class="
              header-menu-nav__link
              {{if this.args.inColumn 'header-menu-nav__link__in_column'}}
              {{if this.args.active 'active'}}
              {{if this.args.shouldAnimate 'header-menu-nav__link__animated'}}
          "
            {{on "mouseenter" this.onMouseEnter}}
            {{on "click" this.onClick}}
          >
            {{#if (and this.args.item.promotion this.args.viewPromotion)}}
              <span>
                <span class="menu-item-content">
                  <span>{{this.args.item.title}}</span>
    
                  <span class="menu-item-chip">
                    {{this.args.item.promotion}}
                  </span>
                </span>
              </span>
            {{else}}
              <span>{{this.args.item.title}}</span>
            {{/if}}
          </button>
        {{/unless}}
      
    */
    {
      "id": "3iNcsAmU",
      "block": "[[[1,\"\\n\"],[41,[51,[30,0,[\"isDisabled\"]]],[[[1,\"      \"],[11,\"button\"],[16,0,[29,[\"\\n          header-menu-nav__link\\n          \",[52,[30,0,[\"args\",\"inColumn\"]],\"header-menu-nav__link__in_column\"],\"\\n          \",[52,[30,0,[\"args\",\"active\"]],\"active\"],\"\\n          \",[52,[30,0,[\"args\",\"shouldAnimate\"]],\"header-menu-nav__link__animated\"],\"\\n      \"]]],[24,4,\"button\"],[4,[32,0],[\"mouseenter\",[30,0,[\"onMouseEnter\"]]],null],[4,[32,0],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n\"],[41,[28,[32,1],[[30,0,[\"args\",\"item\",\"promotion\"]],[30,0,[\"args\",\"viewPromotion\"]]],null],[[[1,\"          \"],[10,1],[12],[1,\"\\n            \"],[10,1],[14,0,\"menu-item-content\"],[12],[1,\"\\n              \"],[10,1],[12],[1,[30,0,[\"args\",\"item\",\"title\"]]],[13],[1,\"\\n\\n              \"],[10,1],[14,0,\"menu-item-chip\"],[12],[1,\"\\n                \"],[1,[30,0,[\"args\",\"item\",\"promotion\"]]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[12],[1,[30,0,[\"args\",\"item\",\"title\"]]],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"unless\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-navigation/discourse/components/header/menu/button.js",
      "scope": () => [_modifier.on, _truthHelpers.and],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Button;
});