define("discourse/plugins/discourse-navigation/discourse/components/header-navigation", ["exports", "@glimmer/component", "discourse/lib/preload-store", "@glimmer/tracking", "@ember/object", "truth-helpers", "discourse/plugins/discourse-navigation/discourse/components/helpers", "discourse/plugins/discourse-navigation/discourse/components/header/logo", "discourse/plugins/discourse-navigation/discourse/components/header/navigation/link", "discourse/plugins/discourse-navigation/discourse/components/header/navigation/button", "discourse/plugins/discourse-navigation/discourse/components/header/whatsapp-button", "discourse/plugins/discourse-navigation/discourse/components/header/cta-button", "discourse/plugins/discourse-navigation/discourse/components/header/menu", "discourse/plugins/discourse-navigation/discourse/components/header/burger-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _preloadStore, _tracking, _object, _truthHelpers, _helpers, _logo, _link, _button, _whatsappButton, _ctaButton, _menu, _burgerButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SCROLL_LOCK_CLASS = 'body-scroll-locked';
  class HeaderNavigation extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "data", [_tracking.tracked], function () {
      return null;
    }))();
    #data = (() => (dt7948.i(this, "data"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "activeNavItem", [_tracking.tracked], function () {
      return null;
    }))();
    #activeNavItem = (() => (dt7948.i(this, "activeNavItem"), void 0))();
    constructor() {
      super(...arguments);
      this.getNavigationData();
    }
    willDestroy() {
      super.willDestroy(...arguments);
      document.documentElement.classList.remove(SCROLL_LOCK_CLASS);
    }
    getMergedNavItems = nav => {
      return nav.itemsCollection.items.map(item => {
        if (!item.itemsCollection) {
          return item;
        }
        const courseTypeNavItem = item.itemsCollection.items[0];
        if (item.mergeNavItems) {
          item.itemsCollection.items = courseTypeNavItem.itemsCollection.items;
          item.sys.id = courseTypeNavItem.sys.id;
          item.mergeNavItems = false;
        }
        return item;
      });
    };
    getNavigationData() {
      const siteData = _preloadStore.default.get('site');
      if (siteData && siteData.navigation_data) {
        this.data = siteData.navigation_data[(0, _helpers.getLocale)()].header;
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "getNavigationData", [_object.action]))();
    setActiveNav(itemId) {
      document.documentElement.classList.add(SCROLL_LOCK_CLASS);
      this.activeNavItem = this.data.itemsCollection.items.find(item => item.sys.id === itemId);
    }
    static #_4 = (() => dt7948.n(this.prototype, "setActiveNav", [_object.action]))();
    resetActiveNav() {
      document.documentElement.classList.remove(SCROLL_LOCK_CLASS);
      this.activeNavItem = null;
    }
    static #_5 = (() => dt7948.n(this.prototype, "resetActiveNav", [_object.action]))();
    toggleMobileMenu() {
      document.documentElement.classList.toggle(SCROLL_LOCK_CLASS);
      if (this.activeNavItem) {
        this.activeNavItem = null;
        return;
      }
      this.activeNavItem = {
        itemsCollection: {
          items: this.getMergedNavItems(this.data)
        }
      };
    }
    static #_6 = (() => dt7948.n(this.prototype, "toggleMobileMenu", [_object.action]))();
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="nav__wrapper">
          <header class="header {{if this.activeNavItem 'active'}}">
            <div class="header__inner header__max_width">
              {{Logo}}
    
              <div class="header__navigation">
                {{#each this.data.itemsCollection.items as |item|}}
                  {{#if (eq item.__typename 'Nav')}}
                    <Button
                      @label={{item.title}}
                      @active={{eq this.activeNavItem item}}
                      @itemId="{{item.sys.id}}"
                      @onClick={{this.setActiveNav}}
                    />
                  {{/if}}
    
                  {{#if (eq item.__typename 'Page')}}
                    <Link
                      @label={{or item.navTitle item.title}}
                      @href={{getMainWebsiteUrl item.slug}}
                    />
                  {{/if}}
                {{/each}}
              </div>
    
              <!-- <LangSwitcher /> -->
    
              <div class="header__actions">
                {{WhatsappButton}}
                {{CTAButton}}
              </div>
    
              <BurgerButton
                @onClick={{this.toggleMobileMenu}}
                @isActive={{this.activeNavItem}}
              />
            </div>
          </header>
    
          <Menu
            @activeNavItem={{this.activeNavItem}}
            @onClose={{this.resetActiveNav}}
          />
        </div>
      
    */
    {
      "id": "WbT4X2t2",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"nav__wrapper\"],[12],[1,\"\\n      \"],[10,\"header\"],[15,0,[29,[\"header \",[52,[30,0,[\"activeNavItem\"]],\"active\"]]]],[12],[1,\"\\n        \"],[10,0],[14,0,\"header__inner header__max_width\"],[12],[1,\"\\n          \"],[1,[32,0]],[1,\"\\n\\n          \"],[10,0],[14,0,\"header__navigation\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"data\",\"itemsCollection\",\"items\"]]],null]],null],null,[[[41,[28,[32,1],[[30,1,[\"__typename\"]],\"Nav\"],null],[[[1,\"                \"],[8,[32,2],null,[[\"@label\",\"@active\",\"@itemId\",\"@onClick\"],[[30,1,[\"title\"]],[28,[32,1],[[30,0,[\"activeNavItem\"]],[30,1]],null],[29,[[30,1,[\"sys\",\"id\"]]]],[30,0,[\"setActiveNav\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[32,1],[[30,1,[\"__typename\"]],\"Page\"],null],[[[1,\"                \"],[8,[32,3],null,[[\"@label\",\"@href\"],[[28,[32,4],[[30,1,[\"navTitle\"]],[30,1,[\"title\"]]],null],[28,[32,5],[[30,1,[\"slug\"]]],null]]],null],[1,\"\\n\"]],[]],null]],[1]],null],[1,\"          \"],[13],[1,\"\\n\\n          \"],[3,\" <LangSwitcher /> \"],[1,\"\\n\\n          \"],[10,0],[14,0,\"header__actions\"],[12],[1,\"\\n            \"],[1,[32,6]],[1,\"\\n            \"],[1,[32,7]],[1,\"\\n          \"],[13],[1,\"\\n\\n          \"],[8,[32,8],null,[[\"@onClick\",\"@isActive\"],[[30,0,[\"toggleMobileMenu\"]],[30,0,[\"activeNavItem\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[32,9],null,[[\"@activeNavItem\",\"@onClose\"],[[30,0,[\"activeNavItem\"]],[30,0,[\"resetActiveNav\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"item\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-navigation/discourse/components/header-navigation.js",
      "scope": () => [_logo.default, _truthHelpers.eq, _button.default, _link.default, _truthHelpers.or, _helpers.getMainWebsiteUrl, _whatsappButton.default, _ctaButton.default, _burgerButton.default, _menu.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = HeaderNavigation;
});