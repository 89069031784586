define("discourse/plugins/discourse-navigation/discourse/templates/connectors/footer-navigation/view", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{FooterNavigation}}
  */
  {
    "id": "LdtgUBdc",
    "block": "[[[41,[28,[32,0],[\"FooterNavigation\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"FooterNavigation\"],[[28,[32,1],[\"FooterNavigation\"],null]]]]],[[[1,[52,[30,1,[\"FooterNavigation\"]],[28,[30,1,[\"FooterNavigation\"]],null,null],[28,[32,2],[[30,0],\"FooterNavigation\",\"[\\\"The `FooterNavigation` property path was used in the `discourse/plugins/discourse-navigation/discourse/templates/connectors/footer-navigation/view.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.FooterNavigation}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]]],[\"maybeHelpers\"],false,[\"if\",\"footer-navigation\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-navigation/discourse/templates/connectors/footer-navigation/view.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});